<template>
  <div class="">
    <HeaderMain />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
